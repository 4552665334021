import React from "react";

import {CarbonPage} from "../CarbonPage";
import {CarbonError} from "./_error";

const _404: CarbonPage = (): React.ReactElement => <CarbonError statusCode={404} />;

_404.optOutOfPracticeData = true;

export default _404;
